<template>
  <div class="page">
    <Header :loginState="loginState" v-if="!isClient"/>
    <div class="account-page">
      <left-menu />
      <div class="account-center">
        <div class="center-box account-information">
          <transaction-nav />
          <div class="title-box">           
            <div class="cfx"></div>
            <p>{{$t('accountCenter.withdrawTitle1')}}</p>
          </div>
          <div class="account-data withdraw-box">
            <div class="withdraw-top">
              <div class="top-box">
                <span>{{$t('accountCenter.withdrawTitle2')}}</span>
                <div class="tips-btn">
                  <img src="@/assets/images/account/wh-icon.png" alt="">
                  <div class="tips-box">
                    <p class="tips-text">{{$t('accountCenter.withdrawTips6')}}</p>
                  </div>
                </div> 
              </div>
              <p class="p2"><span>{{withdrawBalance}}</span><span>USD</span></p>
            </div>
            <div class="sum-box">
                <div class="sum-list">
                  <p class="title">{{$t('accountCenter.withdrawTitle3')}}</p>
                  <div class="input-main">
                    <input type="text" v-model="money">
                    <div class="unit">
                      USD
                    </div>
                  </div>              
                </div>
                <p class="be-to">=</p>
                <div class="sum-list">
                  <p class="title">{{$t('accountCenter.withdrawTitle4')}}</p>
                  <div class="input-main">
                    <input type="text" :value="(money * withdrawConfig.outExchangeRate).toFixed(2)" disabled>
                    <div class="unit">
                      {{paymentCurrency}}
                    </div>
                  </div>   
                </div>           
              </div>
              <p class="err-text" v-if="money < withdrawConfig.outMinimum || money > withdrawConfig.outMaximum">
                  <img src="@/assets/images/register/dx-icon.png" alt="" />
                  <span >{{$t('accountCenter.withdrawTips3')}}：{{withdrawConfig.outMinimum}}（USD），{{$t('accountCenter.withdrawTips4')}}：{{withdrawConfig.outMaximum}}（USD）</span>
              </p> 
              <p class="err-text" v-else-if="parseInt(money) > parseInt(withdrawBalance)">
                <img src="@/assets/images/register/dx-icon.png" alt="" />
                <span>{{$t('accountCenter.withdrawTips5')}}</span>
              </p>
              <div class="withdraw-list">
                <p class="title">{{$t('accountCenter.withdrawTitle5')}}</p>
                <div class="input-main">
                    <input :value="userInfo.realName" type="text" disabled>
                  </div>
              </div>
              <div class="withdraw-list">
                <p class="title">{{$t('accountCenter.withdrawTitle6')}}</p>
                <div class="input-main">
                    <input :value="userInfo.bankCardNumber" type="text" disabled>
                  </div>
              </div>
              <button class="submit-btn" @click="SubmitWithdraw">{{$t('accountCenter.subWithdraw')}}</button>

              <!-- 取消出金申请弹窗 -->
            <div class="Withdraw-data-modal">
              <div class="mask" v-if="showModal1"></div>
              <div class="pop" v-if="showModal1">
                <div class="data-box">
                  <!-- <img @click="confirmShow" class="close-btn" src="@/assets/images/transaction/close-icon.png" alt=""> -->
                  <div class="reward-box succes-box">
                    <div class="reward-data">
                      <img class="cg-icon" src="@/assets/images/transaction/cjcg-img.png" alt="">
                      <p class="title">{{$t('accountCenter.applySucc')}}</p>
                      <div class="succ-bz">
                        <div class="bz-box">
                          <div class="sum sum-s">1</div>
                          <div class="zhx zhx-s"></div>
                          <div class="sum">2</div>
                          <div class="zhx"></div>
                          <div class="sum">3</div>
                          <div class="zhx"></div>
                          <div class="sum">4</div>
                          <div class="ts-box">
                            <p><span>{{$t('accountCenter.applySuccStep4')}}</span><img src="@/assets/images/transaction/xl-s-icon.png" alt=""></p>                           
                          </div>
                        </div>
                        <div class="bz-sm">
                          <p>{{$t('accountCenter.applySucc')}}</p>
                          <p>{{$t('accountCenter.applySuccStep1')}}</p>
                          <p>{{$t('accountCenter.applySuccStep2')}}</p>
                          <p>{{$t('accountCenter.applySuccStep3')}}</p>
                        </div>
                      </div>
                      <div class="button-box">
                        <button @click="cancelWithdraw" class="cancel">{{$t('accountCenter.cancelWithdraw')}}</button>
                        <button @click="determineWithdraw" class="determine">{{$t('perfectData.determine')}}</button>
                      </div>
                      <div class="tips-box">
                        <p class="p1">{{$t('accountCenter.reminder1')}}</p>
                        <p class="p2">{{$t('accountCenter.withdrawTips1')}}</p>
                        <p class="p3">{{$t('accountCenter.withdrawTips2')}}</p>
                      </div>
                    </div>               
                  </div>                     
                </div>     
              </div>          
            </div>
            <!-- 出金申请失败弹窗 -->
            <div class="Withdraw-data-modal">
              <div class="mask" v-if="showModal2"></div>
              <div class="pop" v-if="showModal2">
                <div class="data-box">
                  <!-- <img @click="confirmShow" class="close-btn" src="@/assets/images/transaction/close-icon.png" alt=""> -->
                  <div class="reward-box succes-box">
                    <div class="reward-data">
                      <img class="cg-icon" src="@/assets/images/transaction/cjcg-img.png" alt="">
                      <p class="title">{{$t('accountCenter.applyFail')}}</p>
                      <div class="sb-box">
                        <p>{{errorMessage}}</p>
                      </div>                      
                      <button @click="understandHide" class="understand">{{$t('accountCenter.understand')}}</button> 
                      <!-- <a class="activite-box" href=""><img class="activite-img" src="@/assets/images/transaction/jygg-img.png" alt=""></a> -->
                    </div>               
                  </div>                     
                </div>     
              </div>          
            </div>
          </div>
        </div>
      </div>
      <!-- 提示框组件 -->
      <Dialog ref="myConfirm"  :isShowConfirm="isShowConfirm" @userBehavior="userBehaviorFun" />  
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import LeftMenu from '@/components/LeftMenu.vue';
import TransactionNav from '@/components/TransactionNav.vue';
import Dialog from '@/components/Dialog.vue';
import account from "@/api/account";
import BigNumber from "bignumber.js";
import profit from "@/util/profitsdk";
export default {
  name: "Withdraw",
  components: {
    Header,
    LeftMenu,
    TransactionNav,
    Dialog
  },
  data() {
    return {
      userInfo:{},
      isClient: profit.isClient,
      loginState:2,
      isShowConfirm:false,
      dataOk:false,
      showModal1:false,
      showModal2:false,
      money:0,
      paymentCurrency:'USD',
      withdrawConfig: {
        outMinimum: 0,
        outMaximum: 0,
        outExchangeRate:0
      },
      withdrawId:'',
      withdrawBalance:'--',
      errorMessage:''
    }
  },
  created() {
    account.isWithdrawHoliday().then(res => {
      if (!(res.data.success)){
        const cancel = this.$i18n.t('perfectData.determine');
        const confirm = this.$i18n.t('perfectData.continue');
        const title = this.$i18n.t('accountCenter.reminder');
        this.isShowConfirm = true
        this.$refs.myConfirm.show(res.data.message, {
          type: 'alert',
          cancelText:cancel,
          confirmText: cancel,
          titleText: title,
          data: '我是外界传进来的数据'
        })
      }
    })
    account.findUserInfo().then(res => {
          this.userInfo =  res.data.value;
          if (res.data.value.identityResult != 1){
            this.$router.push({ path: '/authentication' });
            // const cancel = this.$i18n.t('perfectData.determine');
            // const toVerify = this.$i18n.t('perfectData.toVerify');
            // const title = this.$i18n.t('perfectData.perfectDataLeftTitle2');
            // this.isShowConfirm = true
            // this.$refs.myConfirm.show(res.data.message, {
            //   type: 'toVerify',
            //   cancelText:cancel,
            //   confirmText: toVerify,
            //   titleText: title,
            //   data: '我是外界传进来的数据'
            // })
            return;
          }else if (res.data.value.bankCardStatus == 0 || res.data.value.bankCardStatus == 3){
            this.$router.push({ path: '/bankCardVerification' });
            return ;
          }
          return account.getSupportCountry();
    }).then(res => {
          const contrys = res.data.value;
          const countyConfig = contrys.find(contry => contry.countryId === this.userInfo.countryId);
          this.paymentCurrency = countyConfig.paymentCurrency;
    })

    account.getWithdrawConfig().then(res => {
      if (res.data.value){
        this.withdrawConfig = res.data.value;
        this.money = this.withdrawConfig.outMinimum;
      } else {
        const popTitle = this.$i18n.t('perfectData.popPrompt1');
        const cancel = this.$i18n.t('perfectData.determine');
        const confirm = this.$i18n.t('perfectData.continue');
        const title = this.$i18n.t('accountCenter.reminder');
        this.isShowConfirm = true
        this.$refs.myConfirm.show(popTitle, {
            type: 'alert',
            cancelText:cancel,
            confirmText: cancel,
            titleText: title,
            data: '我是外界传进来的数据'
        })
      }
    })

    account.queryTradingAccount().then(res => {
      const account = res.data.value;
      const totalProfit = new BigNumber(account.fL);
      const totalEquity = totalProfit.plus(account.balance).plus(account.credit);
      const marginFree = totalEquity.minus(account.margin)
      this.withdrawBalance = marginFree.minus(account.credit)
          .minus(
              new BigNumber(account.margin).times(0.4)
          ).toFixed(2);
    })
  },
  mounted(){
// 　　this.perfectDataShow();
  },
  methods: {

    //如果没完善资料，页面一进来就显示
    perfectDataShow(){
      if(this.dataOk){
        const popTitle = this.$i18n.t('LoginRegister.popPrompt');
        const cancel = this.$i18n.t('perfectData.cancel');
        const confirm = this.$i18n.t('perfectData.continue');
        const title = this.$i18n.t('accountCenter.reminder');
        this.isShowConfirm = true
        this.$refs.myConfirm.show(popTitle, {
            type: 'confirm',
            cancelText:cancel,
            confirmText: confirm,
            titleText: title,
            data: '我是外界传进来的数据'
        })
      }  
    },

     // 提示框组件传递取消和确定事件,根据对应的事件类型跳转或关闭提示框
    userBehaviorFun(type){
      console.log(type);
      const that = this;
      // if(type === "clickCancel"){
      //   that.$router.push("/");//去账户首页
      //   this.isShowConfirm = false
      // }else if(type === "clickConfirm"){
      //   that.$router.push("/authentication");
      //   this.isShowConfirm = false
      // }
      switch (type) {
        case  'toVerify':
              this.$router.push("/authentication");
              return ;
      }
      this.$router.push("/");
    },

    // 提交出金申请
    SubmitWithdraw(){
      if (this.money >= this.withdrawConfig.outMinimum && this.money <= this.withdrawConfig.outMaximum) {
        account.withdrawApply({
          accountNumber: this.userInfo.spNumber,
          amount: this.money,
          estimateAmount: (this.money * this.withdrawConfig.outExchangeRate).toFixed(2),
          orderCurrency: this.paymentCurrency,
          requestSource: 'uc'
        }).then(res => {
          if (res.data.success){
            this.withdrawId = res.data.value;
            this.showModal1 = true;
          }else {
            this.showModal2 = true;
            this.errorMessage = res.data.message;
          }
        })
      }
    },

    // 取消出金申请
    determineWithdraw(){
      this.showModal1 = false
      this.$router.push({
        path: 'capitalFlow',
        query: {type:'withdraw'}
      });
    },

    // 确定出金申请
    cancelWithdraw(){
      account.withdrawCancel({agentOrderNo:this.withdrawId}).then(res =>{
        this.showModal1 = false
        this.$router.push({
          path: 'capitalFlow',
          query: {type:'withdraw'}
        });
      })
    },

    // 申请失败关闭按钮
    understandHide(){
      this.showModal2 = false
    }
    
  },
};
</script>
<style>
@import url("../assets/css/public.css");
</style>
